"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host,
    :host(:hover) {
        --primary-button-color: var(--primary-color);
        --font-color: #fff;
        --hv-button-text-transform: default;
        --hv-button-height: 48px;
        --border-radius: 5px;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
