'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'Eemnes Energie',
    logoRedirect: 'https://eenwebsitevanappeleneelman.nl',
    electricityAccentColor: '#f2b82a',
    electricityProductionAccentColor: '#f2b82a',
    gasAccentColor: '#f2b82a',
    savingsAccentColor: '#f2b82a',
    energyCostAccentColor: '#f2b82a',
    subtitle: 'Energie besparen, opwekken en uitwisselen in Eemnes',
    logoAppLink: '', // Je kunt hier een redirect invullen. Die is dan voor geauthenticeerde, als niet geauthenticeerde users.
    phoneNumber: '085 808 5144',
    appDomain: 'Eemnes Energie',
    vendorID: '97c065ea-a0d4-11ea-bb37-0242ac130002',
    smartCharging: false
};