'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Welkom',
    body: 'Log in op de klantomgeving.',
    emailPlaceholder: 'naam@domein.nl',
    passwordPlaceholder: 'Wachtwoord',
    submit: 'Inloggen',
    forgotPassword: 'Wachtwoord vergeten?',
    newCustomer: 'Stap over op Eemnes Energie'
};