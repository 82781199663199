"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    icons: `<iron-iconset-svg name="ez-menu" size="24">
    <svg>
        <defs>
        <svg id="dot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M38.42 25c0 7.38-5.78 13-13.42 13s-13.42-5.62-13.42-13S17.36 
                12 25 12s13.42 5.55 13.42 13z" fill="#0f2c52" />
        </svg>

        <svg id="home" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g fill="#0f2c52" fill-rule="nonzero">
                <path d="M0 0h5.185v5.185H0zM8.148 0h5.185v5.185H8.148zM0 8.148h13.333v5.185H0z"/>
            </g>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <path fill="#0f2c52" fill-rule="evenodd" d="M4.22 2h5.653c.004 0 .008.005.006.01L8.252 5.73a.007.007 0 0 0 .006.009h2.554c.005 0 
            .008.007.004.011l-5.604 6.248c-.004.005-.012.001-.011-.005l.391-4.726c0-.004-.003-.007-.006-.007h-1.58C4.004 7.26 4 7.257 4 7.25
            3l.213-5.247c0-.003.003-.006.006-.006"/>
        </svg>

        <svg id="gas" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
            <path fill="#0f2c52" fill-rule="evenodd" d="M.5 12.734c0 2.95 1.99 5.459 
            4.777 6.432-.567-.681-.853-1.49-.853-2.052 0-1.718 3.237-4.295 3.402-4.425.178.13 
            3.415 2.707 3.415 4.425 0 .562-.287 1.37-.867 2.033 2.8-.954 4.79-3.463 4.79-6.413 
            0-5.824-8.248-11.6-8.68-11.899C6.454 8.727.5 7.09.5 12.734z"/>
        </svg>

        <svg id="production" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <defs>
                <style>
                    .cls-1 {
                        fill: #0f2c52
                    }
                </style>
            </defs>
            <circle class="cls-1" cx="24.78" cy="25.22" r="6.8" />
            <path class="cls-1" d="M16.47 18a1.52 1.52 0 0 1-1.07-.45l-3.08-3.08a1.59 
                1.59 0 0 1-.17-2.12 1.53 1.53 0 0 1 2.25-.11l3.07 3.08a1.61 1.61 0 0 1 .15 
                2.16 1.53 1.53 0 0 1-1.15.52zM36.68 38.2a1.55 1.55 0 0 1-1.08-.44l-3.08-3.08a1.6 1.6 0 0 
                1-.16-2.13 1.52 1.52 0 0 1 2.24-.1l3.08 3.07a1.61 1.61 0 0 1 .15 2.16 1.53 1.53 0 0 
                1-1.15.52zM24.86 15a1.59 1.59 0 0 1-1.38-1.62V9.13a1.59 1.59 0 0 1 1.38-1.62A1.52 1.52 
                0 0 1 26.52 9v4.44A1.52 1.52 0 0 1 24.86 15zM24.86 42.49a1.59 1.59 0 0 1-1.38-1.62v-4.22A1.59 
                1.59 0 0 1 24.86 35a1.52 1.52 0 0 1 1.66 1.51V41a1.52 1.52 0 0 1-1.66 1.49zM41 26.52h-4.35A1.59 
                1.59 0 0 1 35 25.14a1.52 1.52 0 0 1 1.51-1.66h4.33a1.59 1.59 0 0 1 1.62 1.38A1.52 1.52 0 
                0 1 41 26.52zM13.46 26.52H9.13a1.59 1.59 0 0 1-1.62-1.38A1.52 1.52 0 0 1 9 23.48h4.33A1.59 
                1.59 0 0 1 15 24.86a1.52 1.52 0 0 1-1.54 1.66zM33.53 18a1.53 1.53 0 0 1-1.15-.52 1.61 
                1.61 0 0 1 .15-2.16l3-3a1.59 1.59 0 0 1 2.12-.17 1.53 1.53 0 0 1 .11 2.25l-3.16 3.15a1.52 
                1.52 0 0 1-1.07.45zM13.32 38.2a1.53 1.53 0 0 1-1.15-.52 1.61 1.61 0 0 1 .15-2.16l3-3a1.6 
                1.6 0 0 1 2.13-.16 1.52 1.52 0 0 1 .1 2.24l-3.15 3.16a1.55 1.55 0 0 1-1.08.44z"
            />
        </svg>

        <svg id="invoices" xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
            <path fill="#0f2c52" fill-rule="nonzero" d="M4.25 4v13.333h10.833V4H4.25zm7.5-1.667H2.583V14H.917V.667H11.75v1.666z"/>
        </svg>

        <svg id="savings" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <g fill="none" fill-rule="nonzero">
                <circle cx="9.167" cy="9.167" r="9.167" fill="#0f2c52"/>
                <g fill="#fff">
                    <path d="M9.245 4.267l4.267 4.267H4.978zM9.245 14.224L4.978 9.957h8.534z"/>
                </g>
            </g>
        </svg>

        <svg id="account" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle"
        class="svg-inline--fa fa-user-circle fa-w-16"
        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
            <path fill="#0f2c52" 
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 
            39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 
            18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 
            40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z">
            </path>
        </svg>

        <svg id="invoices" xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
            <path fill="#0f2c52" fill-rule="nonzero" d="M4.25 4v13.333h10.833V4H4.25zm7.5-1.667H2.583V14H.917V.667H11.75v1.666z"/>
        </svg>

        <svg id="coins" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <g fill="none" fill-rule="evenodd">
                <path fill="#0f2c52" d="M14.23.918c7.351 0 13.31 5.96 13.31 13.312 0 7.351-5.959 13.31-13.31
                    13.31C6.878 27.54.918 21.582.918 14.23.918 6.878 6.878.918 14.23.918z"/>
                <path fill="#FFFFFF" d="M19.626 17.69l1.441 1.63c-1.462 1.462-3.572 2.402-5.66 2.402-3.405
                    0-6.204-2.047-7.207-5.013H5.923v-1.337h1.964a6.185 6.185 0 0
                    1-.084-1.087c0-.376.02-.752.084-1.107H5.923v-1.336h2.298c1.023-2.904 3.843-4.91 7.29-4.91
                    2.068 0 4.136.857 5.535 2.257l-1.42 1.796c-1.086-1.149-2.59-1.859-4.094-1.859-2.005 0-3.74
                    1.086-4.617 2.716h5.243v1.336h-5.744c-.063.355-.104.71-.104 1.087 0 .376.041.752.125
                    1.107h5.723v1.337h-5.243c.878 1.629 2.612 2.715 4.617 2.715 1.462 0 2.966-.668 4.094-1.734z"/>
            </g>
        </svg>

        <svg id="hamburger" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 50 50">
            <defs>
                <style>.cls-1{fill:#fff}</style>
            </defs>
            <path 
            class="cls-1" d="M43.2 27.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 
            14.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 40.5a2.5 2.5 0 0 0 
            0-5H6.8a2.5 2.5 0 1 0 0 5z"/>
        </svg>

        <svg id="arrow-left" aria-hidden="true" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" ` + `viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-3x">
            <path fill="#fff" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 ` + `0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 ` + `24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
        </svg>

        <svg id="arrow-right" aria-hidden="true" data-prefix="fas" data-icon="arrow-right" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x">
            <path fill="#fff" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 ` + `33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 ` + `0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path>
        </svg>

        <svg id="address" aria-hidden="true" focusable="false" 
        data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12"
        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="#0f2c52" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97
            99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817
            80 80 80z">
            </path>
        </svg>
        <svg id="addresses" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#0f2c52" d="M11 42q-1.25 0-2.125-.875T8 39V19.5q0-.7.325-1.35.325-.65.875-1.05l13-9.75q.4-.3.85-.45.45-.15.95-.15.5 0
            .95.15.45.15.85.45l13 9.75q.55.4.875 1.05.325.65.325 1.35V39q0 1.25-.875 2.125T37 42h-9V28h-8v14Z"/>
        </svg>
        <svg id="logout" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#0f2c52" 
            d="M32.25 31.65q-.45-.45-.45-1.1 0-.65.45-1.05l4-4h-16q-.65 0-1.075-.425-.425-.425-.425-1.075
            0-.65.425-1.075.425-.425 1.075-.425h15.9l-4.05-4.05q-.4-.4-.4-1.025 0-.625.45-1.075.45-.45 1.075-.45t1.075.45L40.95
            23q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-6.6 6.6q-.4.4-1.025.4-.625 0-1.075-.45ZM9 42q-1.2 0-2.1-.9Q6 40.2
            6 39V9q0-1.2.9-2.1Q7.8 6 9 6h13.05q.65 0 1.075.425.425.425.425 1.075 0 .65-.425
            1.075Q22.7 9 22.05 9H9v30h13.05q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q22.7 42 22.05 42Z"/>
        </svg>
        </defs>
    </svg>
</iron-iconset-svg>`
};