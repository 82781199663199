'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  menuPositionVariant: 1,
  menuSelectedPostAgree: 3,
  menuSelectedPreAgree: 2,
  /**
   * Menu position variants
   * 1. Sign mandate and pay for meter on page
   * 2. Show button to go to app and sign mandate and pay for meter in app
   */
  postAgreeVariant: 1,
  termsOfServiceURL: 'https://eenwebsitevanappeleneelman.nl/voorwaarden-energiediensten/',
  vendor: 'Eemnes Energie',
  vendorPhoneNumber: '085 808 5144',
  vendorCheckBoxActive: true,
  vendorCheckBoxActiveText: `Ja, ik geef toestemming dat Eemnes Energie inzicht heeft in mijn 
    klantgegevens en deze gebruikt om jou te informeren over de afgesproken dienstverlening.`
};