"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    barColor: '#f2b82a',
    barHoverColor: '#f2b82a',
    lineColor: '#95a4ac',
    axisLabelColor: '#285685',
    legendaInformation: ``,
};
