'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Ben jij al energieklant?',
    bodyText: `Wil je nog een contract toevoegen of verlengen? Log dan in en je gegevens worden automatisch opgehaald.`,
    buttonExistingCustomerText: 'Ja, ik wil inloggen',
    buttonNewCustomerText: 'Nee, ik ben nieuw',
    backButtonText: 'Vorige stap'
};