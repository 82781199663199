'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Wachtwoord vergeten',
    body: 'Vul hieronder je e-mailadres in.',
    inputPlaceholder: 'naam@domein.nl',
    submit: 'Wachtwoord reset'
};